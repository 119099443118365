<template>
  <div class="privacyPolicy">
    <pageHeader />

    <div class="content content-padding">
      <v-container fluid>
        <h2 class="font-bold main-color">سياسة الخصوصية</h2>
        <hr class="main-hr" />

        <p class="text">{{ pageData }}</p>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import pageHeader from "@/components/pageHeader.vue";
export default {
  name: "term",
  components: { pageHeader },
  data() {
    return {
      pageData: "",
    };
  },
  computed: {
    ...mapGetters(["base_url"]),
  },
  methods: {
    getpageData() {
      this.$http
        .get(this.base_url + "/front/privacy_policy", {})
        .then((response) => {
          this.pageData = response.data.data;
        });
    },
  },
  mounted() {
    this.getpageData();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/_variables.scss";

.content {
  padding: 80px 80px;
}
@media (max-width: 600px) {
  .content {
    padding: 40px 12px;
  }
}
</style>
